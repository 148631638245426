import { render, staticRenderFns } from "./verifyCode.vue?vue&type=template&id=cb5c8648&scoped=true"
import script from "./verifyCode.vue?vue&type=script&lang=js"
export * from "./verifyCode.vue?vue&type=script&lang=js"
import style0 from "./verifyCode.vue?vue&type=style&index=0&id=cb5c8648&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb5c8648",
  null
  
)

export default component.exports