<template>
    <div class="emailCode">
        <div class="warp">
            <h5>
                Verification code link has been sent to: <br />
                {{ email }}
            </h5>
            <div class="text">
                <span>Verification code:</span>
                <verifyCode v-model="code"></verifyCode>
                <p :class="codeError ? 'trueP' : ''">Verification code error</p>
                <div class="btn">
                    <button class="btn-border" @click="backClick">CANCEL</button>
                    <button class="btn-background" @click="codeClick">Next</button>
                </div>
            </div>
            <p style="margin-top: 60px">Emails may experience a few minute delay.</p>
            <p>If you have not received an email, please check your junk and spam folders.</p>
            <p>Didn't receive the verification code? <span>Try again</span></p>
        </div>
    </div>
</template>

<script>
import emailCode from "./js/emailCode.js"
import verifyCode from "./verifyCode.vue"

export default {
    name: "emailCode",
    components: {
        verifyCode
    },
    mixins: [emailCode]
}
</script>

<style lang="scss" scoped>
.emailCode {
    margin-top: 40px;
    width: 100%;

    .warp {
        max-width: 1200px;
        width: 100%;
        background: #fff;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 100px;

        h5 {
            width: 422px;
            font-size: 24px;
            font-family: Montserrat;
            font-weight: bold;
            color: #330000;
            line-height: 28px;
        }

        .text {
            width: 422px;
            padding-top: 50px;
            display: flex;
            flex-direction: column;

            span {
                font-size: 12px;
                font-family: Montserrat-Regular, Montserrat;
                font-weight: 400;
                color: #96989a;
                line-height: 14px;
                padding-bottom: 10px;
            }

            p {
                padding-top: 8px;
                font-size: 12px;
                font-family: Montserrat;
                font-weight: 400;
                color: #ff5656;
                line-height: 14px;
                visibility: hidden;
            }
            .trueP {
                visibility: initial;
            }

            .btn {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding-top: 27px;

                button {
                    outline: none;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .btn-border {
                    width: 200px;
                    height: 54px;
                    border: 1px solid #330000;
                    font-size: 18px;
                    font-family: Montserrat;
                    font-weight: bold;
                    color: #330000;
                    background: transparent;
                }

                .btn-background {
                    width: 200px;
                    height: 54px;
                    background: #330000;
                    font-size: 18px;
                    font-family: Montserrat;
                    font-weight: bold;
                    color: #ffffff;
                }
            }
        }

        > p {
            width: 463px;
            padding-left: 30px;
            position: relative;
            font-size: 12px;
            font-family: Montserrat;
            font-weight: 400;
            color: #96989a;
            // line-height: 40px;
            padding-bottom: 20px;

            span {
                color: #437ef5;
                cursor: pointer;
            }
        }
        > p::before {
            content: "";
            position: absolute;
            left: 15px;
            top: 11px;
            transform: translateY(-50%);
            display: block;
            width: 4px;
            height: 4px;
            background: #96989a;
            border-radius: 50%;
        }
    }
}
</style>