<template>
    <div class="verifyCode">
        <input 
            type="text"
            v-for="p in inputNums" 
            :key="p"
            @input="inputFun($event,p)"
            :value="code[p-1]"
            :class="{success: code[p-1]}"
        >
    </div>
</template>

<style scoped lang="scss">
	@mixin border(){
		border: 1px solid #D8DADC;
	}
	.verifyCode{
        input:first-child{
            margin-left: 0;
        }
		input{
			@include border();
			margin-left: 24px;
			width: 48px;
			height: 48px;
            font-size: 30px;
            font-family: Montserrat;
            font-weight: 500;
            color: #330000;
            padding: 0;
			// border-radius: 5px;
			text-align: center;
			&:first{
				margin-left: 0;
			}
			&:focus{
                outline-style: none;
				outline-color: #330000;
			}
			&.success{
				border-color: #330000;
				// border-width: 1px;
				transition: border-color .5s;
				outline: none;
			}
		}
	}
</style>

<script>
export default {
    name: "verifyCode",
    components: {},
	props: {
		inputNums: {
			type: Number,
			default: 4
		},
		value: {
			type: String,
			default: ''
		}
	},
	data(){
		return {
			code: this.value.split('')
		}
	},
	watch: {
		value(newVal){
			this.code = newVal.split('');
		},
		code(newVal){
			this.$emit('input',newVal.join(''));
		}
	},
	methods: {
		//1.监听用的键入，每次键入都替换掉焦点输入框的值
		//2.输入框的值只能有一位
		inputFun(e,p){
			let ele = e.target;
			let siblingsNode = ele.parentNode.children;
			if(!e.data){//删除
				this.$delete(this.code,p-1);
				if(p <= 2){//边界值
					p = 2;
				}
				siblingsNode[p-2].focus();
				return false;
			}
			let value = e.target.value.replace(/\W/g,'').slice(-1);
			ele.value = value;
			this.$set(this.code,p-1,value)
			if(p >= siblingsNode.length-1){//边界值
				p = siblingsNode.length-1;
			}
			if(value){//上一个元素获得焦点
				siblingsNode[p].focus();
			}
		}
	}
}
</script>