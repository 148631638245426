import { getSavePassword } from "@/api/auth/login"

export default {
    name: "emailCode",
    components: {

    },
    data: () => {
        return {
            codeShow: true,
            email: localStorage.getItem("emailSoyego"),
            code: "",
        }
    },
    watch: {
    },
    created() {        
    },
    mounted() {
        this.getCaptcha();
    },
    computed: {
    },
    methods: {
        backClick() {
            this.$router.go(-1);
        },
        codeClick() {
            localStorage.setItem("emailCodeSoyego", this.code);
            this.$router.push({ path: "/modifyPassword" })
        }
    }
}
